const r2Matchups = [
  {
    contenderA:
      {
        mascot: "Digi \"The Dinosaur\"",
        explanation: "Digi -short for digital.\n" +
          "\n" +
          "Dinosaurs made a mark in history and our company aims to make their mark in history as the best digital agency in the nation! Dinosaurs were a force to be reckon with!",
        image: "mascots/party-dinosaur.gif",
        winner: true
      },
    contenderB:
      {
        winner: false,
        mascot: "Shake N Bake",
        explanation: "To celebrate the moments within 2060 of working together/helping others. No better duo than Ricky Bobby and Cal Naughton Jr. They get things done!",
        image: "mascots/talladega-nights-the-ballad-of-ricky-bobby-shake-n-bake-cal-naughton-jr-will-ferrell-john-c-reilly.webp"
      },
  },
  {
    contenderA:
      {
        winner:false,
        mascot: "Gritty",
        explanation: "Gritty needs no explanation",
        image: "mascots/gritty.webp"
      },
    contenderB:
      {
        winner: true,
        mascot: "Swaggy P",
        explanation: "No explanation needed. But because one is asked for, is there anything we renown as cooler than Swaggy P? I think not.",
        image: "mascots/patrick-ding.webp"
      },

  },
  {
    contenderA:
      {
        winner: true,
        mascot: "Dynamite",
        explanation: "C what we can do 4 you! (Get it? C-4??) We blow the competition away!",
        image: "mascots/95110980-dynamite-mascot-and-background-with-selfie-pose-with-flat-design-style-for-your-mascot-branding-.webp"
      },
    contenderB:
      {
        winner: false,
        mascot: "Dread Pirate Roberts",
        explanation: "As you wish...",
        image: "mascots/QMx_PB_Westley_1-6_Articulated-01_988x988_4bc07595-778b-402b-a727-83b57e8dc584_grande.webp"
      },
  },
  {
    contenderA:
      {
        winner: false,
        mascot: "T.S. Digit",
        explanation: "A pixel from deep within hyperspace. T.S. (Twenty Sixity) Digit is the virtual form of a 'high five' or five of your digits raised to emphasize great work with a reciprocating number of digits from the excellent co-worker.",
        image: "mascots/TSDigit.webp"
      },
    contenderB:
      {
        winner: true,
        mascot: "Mario Coin",
        explanation: "There's an indefinite number of them to give out and if you get 100 you get a free life!\n" +
          "\n" +
          "Also, Mario embodies the essence of persistence, running through castle after castle, never giving up on finding the princess.",
        image: "mascots/coin.gif"
      },
  },
  {
    contenderA:
      {
        winner: true,
        mascot: "The Pixel",
        explanation: "It might be small, but sometimes going the extra mile for someone requires examining the smallest details.",
        image: "mascots/mysterySquare.gif"
      },
    contenderB:
      {
        winner: false,
        mascot: "Tiny Smile",
        explanation: "It's the small things we do for each other that truly make the largest impact. When we give 'em a tiny smile™, we almost always get a bigger one back.",
        image: "mascots/ec2f090f20bbfc893b973fe0e95d19cf.webp"
      },
  },
  {
    contenderA:
      {
        winner: false,
        mascot: "Dodo",
        explanation: "Honestly its a fun animal and sounds good. 2060 Digital Dodos, simple as that.",
        image: "mascots/dodo.webp"
      },
    contenderB:
      {
        winner: true,
        mascot: "Digi",
        explanation: "First off I envision graphics making this look A LOT better haha but.... my vision is a little 2060 mascot played off of our logo, called Digi. He likes to bring praise & cheer when a team member has done well. Digi says: GO TEAM!",
        image: "mascots/Digi.webp"
      },
  },
  {
    contenderA:
      {
        winner: true,
        mascot: "Cheese Coney",
        explanation: "Doesn't matter if you're a fan of Skyline or Gold Star, everyone loves cheese coneys. Let's send each other coneys for good work and help!",
        image: "mascots/coney.webp"
      },
    contenderB:
      {
        winner: false,
        mascot: "Natural 20 (60 Digital)",
        explanation: "We're nerds. We crit. We're natural 20s",
        image: "mascots/Naty20.webp"
      },
  },
  {
    contenderA:
      {
        winner: false,
        mascot: "The 2060 Kraken",
        explanation: "The Kraken represents wonder and strength",
        image: "mascots/Kraken.webp"
      },
    contenderB:
      {
        winner: true,
        mascot: "A Round On The House",
        explanation: "Cheers to you! Nothing says \"thank you\" like having someone else pay for your beverage of choice.",
        image: "mascots/beers.webp"
      },
  },
];

export default r2Matchups;