import React, {useState, useEffect} from 'react'
import initialMatchups from "../config/initialMatchups"
import r2Matchups from "../config/r2Matchups"
import r3Matchups from "../config/r3Matchups"
import r4Matchups from "../config/r4Matchups"
import r5Matchup from "../config/r5Matchups"


import Firebase from "../services/firebase"

import Layout from '../components/layout'
import SEO from '../components/seo'
import Header from '../components/header'
import Matchup from '../components/matchup'
import '../styles/bracket.css';

const emptyVotes = {
  votesA: 0,
  votesB: 0
}


const Bracket = () => {

  const [talliedVotes, setTalliedVotes] = useState([])


  useEffect(() => {

    let votes = []

    for(let i = 0; i < 16; i++) {
      votes.push(Object.assign({}, emptyVotes))
    }

    Firebase.store.collection("votesr4").get().then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
        let ballot = doc.data()
        for (const index in ballot) {
          if (ballot[index] === "A") {
            votes[index - 1].votesA++
          } else if (ballot[index] === "B") {
            votes[index - 1].votesB++
          }
        }
      });
    }).then(() => {
      setTalliedVotes(votes)
    }).catch(function (error) {
      console.error("Error getting previous votes:", error)
    });
    return
  }, [])


  const firstBracket = initialMatchups.slice(0, 8)
  const secondBracket = initialMatchups.slice(8)

  const r2FirstBracket = r2Matchups.slice(0,4)
  const r2SecondBracket = r2Matchups.slice(4)

  const r3FirstBracket = r3Matchups.slice(0,2)
  const r3SecondBracket = r3Matchups.slice(2)

  const r4FirstBracket = r4Matchups.slice(0,1)
  const r4SecondBracket = r4Matchups.slice(1)


  const fillEmptyMatchups = ( count ) => {
    return (
      <>
        {[...Array(count)].map((item, i) => {
          return (
            <ul className="matchup" key={i}>
              <li className="team team-top">-</li>
              <li className="team team-bottom">-</li>
            </ul>
          )
        })}
      </>
    )
  }


  return (
    <Layout>
      <SEO title="Home"/>
      <Header title="Final Round"/>
      <section className="all-initialMatchups">
        <div id="bracket">
          <div className="container">
            <div className="split split-one">
              <div className="round round-one">
                <div className="round-details">Round 1<br/><span className="date">Nov 23 - 30</span></div>

                {firstBracket.map((matchup, i) => {
                  return <Matchup matchup={matchup} side="left" key={i} />
                })}
              </div>
              <div className="round round-two">
                <div className="round-details">Round 2<br/><span className="date">Dec 1 - 7</span></div>
                {r2FirstBracket.map((matchup, i) => {
                  return <Matchup matchup={matchup} side="left" key={i} />
                })}
              </div>

              <div className="round round-three">
                <div className="round-details">Round 3<br/><span className="date">Dec 8 - 14</span></div>
                {r3FirstBracket.map((matchup, i) => {
                  return <Matchup matchup={matchup} side="left" key={i} />
                })}
              </div>

              <div className="round round-four">
                <div className="round-details">Round 4<br/><span className="date">Dec 15 - 21</span></div>
                {r4FirstBracket.map((matchup, i) => {
                  return <Matchup  votes={talliedVotes[i]}  matchup={matchup} side="left" key={i} />
                })}
              </div>
            </div>
            <div className="split split-middle">
              <div className="round round-five current">
                <div className="round-details">
                  <span className="championship">Championship<br/><span className="date">Dec 22 - Jan 4</span></span>
                  {r5Matchup.map((matchup, i) => {
                    return <Matchup final matchup={matchup} key={i} />
                  })}
                </div>
              </div>
            </div>

            <div className="split split-two">

              <div className="round round-four">
                <div className="round-details">Round 4<br/><span className="date">Dec 15 - 21</span></div>
                {r4SecondBracket.map((matchup, i) => {
                  return <Matchup votes={talliedVotes[i+1]} matchup={matchup} side="left" key={i} />
                })}
              </div>

              <div className="round round-three">
                <div className="round-details">Round 3<br/><span className="date">Dec 8 - 14</span></div>
                {r3SecondBracket.map((matchup, i) => {
                  return <Matchup matchup={matchup} side="right" key={i} />
                })}
              </div>

              <div className="round round-two">
                <div className="round-details">Round 2<br/><span className="date">Dec 1 - 7</span></div>
                {r2SecondBracket.map((matchup, i) => {
                  return <Matchup matchup={matchup} side="right" key={i} />
                })}
              </div>
              <div className="round round-one">
                <div className="round-details">Round 1<br/><span className="date">Nov 23 - 30</span></div>
                {secondBracket.map((matchup, i) => {
                  return <Matchup matchup={matchup} side="right" key={i} />
                })}
              </div>
            </div>
          </div>

          <div className="final-mobile">
            <div>
              <span className="championship">Championship<br/><span className="date">Dec 22 - Jan 4</span></span>
              <ul className="matchup championship-mobile">
                {r5Matchup.map((matchup, i) => {
                  return <Matchup final matchup={matchup} key={i} />
                })}
              </ul>
            </div>
          </div>
          <footer className="brackets-footer">
            <h2>There can be but <span>one</span> winner.</h2>
            <a className="back" href="/">&larr; Cast your votes now.</a>
          </footer>
        </div>
      </section>
    </Layout>
  );
}

export default Bracket;
