const initialMatchups = [
  {
    contenderA:
      {
        mascot: "Baby Yoda",
        explanation: "Cute and wise?",
        image: "mascots/babyyoda.webp",
        winner: false
      },
    contenderB:
      {
        mascot: "Digi \"The Dinosaur\"",
        explanation: "Digi -short for digital.\n" +
          "\n" +
          "Dinosaurs made a mark in history and our company aims to make their mark in history as the best digital agency in the nation! Dinosaurs were a force to be reckon with!",
        image: "mascots/party-dinosaur.gif",
        winner: true
      },
  },
  {
    contenderA:
      {
        mascot: "Cheers!",
        explanation: "The great Leonardo DiCaprio in The Great Gatsby. Kind to all visitors and understanding of you. Just as we are understanding of and believe in each other!",
        image: "mascots/3158d36c3f24f74e342e09813fa2562c.webp",
        winner: false
      },
    contenderB:
      {
        mascot: "Shake N Bake",
        explanation: "To celebrate the moments within 2060 of working together/helping others. No better duo than Ricky Bobby and Cal Naughton Jr. They get things done!",
        image: "mascots/talladega-nights-the-ballad-of-ricky-bobby-shake-n-bake-cal-naughton-jr-will-ferrell-john-c-reilly.webp",
        winner: true
      },
  },
  {
    contenderA:
      {
        mascot: "Gritty",
        explanation: "Gritty needs no explanation",
        image: "mascots/gritty.webp",
        winner: true
      },
    contenderB:
      {
        mascot: "Gritty's Mom",
        explanation: "Gritty is a beloved mascot across the US and it's time to give the same love to the most important woman in his life: his mother. She puts a smile on everyone's face for obvious reasons and deserves the recognition this position would provide her.",
        image: "mascots/EnCLIYhWMAASvCt.webp",
        winner: false
      },
  },
  {
    contenderA:
      {
        mascot: "Swaggy P",
        explanation: "No explanation needed. But because one is asked for, is there anything we renown as cooler than Swaggy P? I think not.",
        image: "mascots/patrick-ding.webp",
        winner: true
      },
    contenderB:
      {
        mascot: "Danger Noodle",
        explanation: "The dangerous danger noodles will attack the competition and slither into anything for your marketing needs",
        image: "mascots/danger-noodle-via-9gag-com-r-e-et-g-d-no-a-0-14732598.webp",
        winner: false
      },
  },
  {
    contenderA:
      {
        mascot: "Rhino",
        explanation: "Missed the deadline. Can you add a Rhino as my submission? -Steve",
        image: "mascots/rhino.webp",
        winner: false
      },
    contenderB:
      {
        mascot: "Dynamite",
        explanation: "C what we can do 4 you! (Get it? C-4??) We blow the competition away!",
        image: "mascots/95110980-dynamite-mascot-and-background-with-selfie-pose-with-flat-design-style-for-your-mascot-branding-.webp",
        winner: true
      },
  },
  {
    contenderA:
      {
        mascot: "Dread Pirate Roberts",
        explanation: "As you wish...",
        image: "mascots/QMx_PB_Westley_1-6_Articulated-01_988x988_4bc07595-778b-402b-a727-83b57e8dc584_grande.webp",
        winner: true
      },
    contenderB:
      {
        mascot: "Zero the Panda",
        explanation: "He's a panda with a zero on his stomach.",
        image: "mascots/zero_thepanda.webp",
        winner: false
      },

  },
  {
    contenderA:
      {
        mascot: "T.S. Digit",
        explanation: "A pixel from deep within hyperspace. T.S. (Twenty Sixity) Digit is the virtual form of a 'high five' or five of your digits raised to emphasize great work with a reciprocating number of digits from the excellent co-worker.",
        image: "mascots/TSDigit.webp",
        winner: true
      },
    contenderB:
      {
        mascot: "Dirt Devil",
        explanation: "WE'LL SWEEP UP THE COMPETITION",
        image: "mascots/470e980b-900a-45d5-b785-657889f93ed9_1.9ffbee239dc71a3f0de14f1029e9038d.webp",
        winner: false
      },
  },
  {
    contenderA:
      {
        mascot: "Mario Coin",
        explanation: "There's an indefinite number of them to give out and if you get 100 you get a free life!\n" +
          "\n" +
          "Also, Mario embodies the essence of persistence, running through castle after castle, never giving up on finding the princess.",
        image: "mascots/coin.gif",
        winner: true
      },
    contenderB:
      {
        mascot: "Mensch",
        explanation: "A mensch is a person of integrity and honor. Our transparency and integrity differentiate us from the competition.",
        image: "mascots/mensch_frontlal-copy.webp",
        winner: false
      },
  },
  {
    contenderA:
      {
        mascot: "Air Horn",
        explanation: "It's that extra sound that the track didn't need, but hyped you up when you heard it. We're dropping digital hits left and right and amping them up with our boy the air horn.",
        image: "mascots/msair8zh.webp",
        winner: false
      },
    contenderB:
      {
        mascot: "The Pixel",
        explanation: "It might be small, but sometimes going the extra mile for someone requires examining the smallest details.",
        image: "mascots/mysterySquare.gif",
        winner: true
      },
  },
  {
    contenderA:
      {
        mascot: "Tiny Smile",
        explanation: "It's the small things we do for each other that truly make the largest impact. When we give 'em a tiny smile™, we almost always get a bigger one back.",
        image: "mascots/ec2f090f20bbfc893b973fe0e95d19cf.webp",
        winner: true
      },
    contenderB:
      {
        mascot: "Rudy the Dog",
        explanation: "Dogs are loyal, hard-working, loving, and will do anything to see you happy.\n" +
          "\n" +
          "This is how we not only should treat our clients (with the care a dog would show), but how we should treat our employees every day as well.  Plus, who doesn't love dogs?\n" +
          "\n" +
          "Rudy The Dog 2024",
        image: "mascots/5ef54cb61918242cfb4ec653.webp",
        winner: false
      },
  },
  {
    contenderA:
      {
        mascot: "Rick Ross",
        explanation: "Everyday he's hustlin' and so are we. Plus, he owns Rally's/Checkers and we have established that they serve the best fries.",
        image: "mascots/RickRoss.webp",
        winner: false
      },
    contenderB:
      {
        mascot: "Dodo",
        explanation: "Honestly its a fun animal and sounds good. 2060 Digital Dodos, simple as that.",
        image: "mascots/dodo.webp",
        winner: true
      },
  },
  {
    contenderA:
      {
        mascot: "Digi",
        explanation: "First off I envision graphics making this look A LOT better haha but.... my vision is a little 2060 mascot played off of our logo, called Digi. He likes to bring praise & cheer when a team member has done well. Digi says: GO TEAM!",
        image: "mascots/Digi.webp",
        winner: true
      },
    contenderB:
      {
        mascot: "ACE",
        explanation: "Always Crushing Expectation (ACE); Ace is a symbol for a highest card and also someone who excels at something. Also, a helpful hardware store - but that isn't relevant. :)",
        image: "mascots/fiery-ace-mascot.webp",
        winner: false
      },
  },
  {
    contenderA:
      {
        mascot: "Cheese Coney",
        explanation: "Doesn't matter if you're a fan of Skyline or Gold Star, everyone loves cheese coneys. Let's send each other coneys for good work and help!",
        image: "mascots/coney.webp",
        winner: true
      },
    contenderB:
      {
        mascot: "Flying Pig",
        explanation: "\"When pigs fly\", let's hear about it! When you see someone going above and beyond, let them know with a Cincy Flying Pig.",
        image: "mascots/flyingpig.webp",
        winner: false
      },
  },
  {
    contenderA:
      {
        mascot: "Natural 20 (60 Digital)",
        explanation: "We're nerds. We crit. We're natural 20s",
        image: "mascots/Naty20.webp",
        winner: true
      },
    contenderB:
      {
        mascot: "Virtual Villagers",
        explanation: "It blends the current state of our workspace with the game (animal crossing) that swept a good portion of the office earlier this year!",
        image: "mascots/villager.webp",
        winner: false
      },
  },
  {
    contenderA:
      {
        mascot: "The 2060 Kraken",
        explanation: "The Kraken represents wonder and strength",
        image: "mascots/Kraken.webp",
        winner: true
      },
    contenderB:
      {
        mascot: "Pickle",
        explanation: "Who says we need to get rid of something that's so easy to preserve? I say \"give em the pickle!\"",
        image: "mascots/pickle.webp",
        winner: false
      },
  },
  {
    contenderA:
      {
        mascot: "A Round On The House",
        explanation: "Cheers to you! Nothing says \"thank you\" like having someone else pay for your beverage of choice.",
        image: "mascots/beers.webp",
        winner: true
      },
    contenderB:
      {
        mascot: "Schrute Buck",
        explanation: "MOTIVATIONAL TOOL",
        image: "mascots/schrutebuck.webp",
        winner: false
      },
  },
];

export default initialMatchups;