const r3Matchups = [
  {
    contenderA:
      {
        winner: true,
        mascot: "Digi \"The Dinosaur\"",
        explanation: "Digi -short for digital.\n" +
          "\n" +
          "Dinosaurs made a mark in history and our company aims to make their mark in history as the best digital agency in the nation! Dinosaurs were a force to be reckon with!",
        image: "mascots/party-dinosaur.gif"
      },
    contenderB:
      {
        winner: false,
        mascot: "Swaggy P",
        explanation: "No explanation needed. But because one is asked for, is there anything we renown as cooler than Swaggy P? I think not.",
        image: "mascots/patrick-ding.webp"
      },
  },
  {
    contenderA:
      {
        winner: false,
        mascot: "Dynamite",
        explanation: "C what we can do 4 you! (Get it? C-4??) We blow the competition away!",
        image: "mascots/95110980-dynamite-mascot-and-background-with-selfie-pose-with-flat-design-style-for-your-mascot-branding-.webp"
      },
    contenderB:
      {
        winner: true,
        mascot: "Mario Coin",
        explanation: "There's an indefinite number of them to give out and if you get 100 you get a free life!\n" +
          "\n" +
          "Also, Mario embodies the essence of persistence, running through castle after castle, never giving up on finding the princess.",
        image: "mascots/coin.gif"
      },
  },
  {
    contenderA:
      {
        winner: false,
        mascot: "The Pixel",
        explanation: "It might be small, but sometimes going the extra mile for someone requires examining the smallest details.",
        image: "mascots/mysterySquare.gif"
      },
    contenderB:
      {
        winner: true,
        mascot: "Digi",
        explanation: "First off I envision graphics making this look A LOT better haha but.... my vision is a little 2060 mascot played off of our logo, called Digi. He likes to bring praise & cheer when a team member has done well. Digi says: GO TEAM!",
        image: "mascots/Digi.webp"
      },
  },
  {
    contenderA:
      {
        winner: false,
        mascot: "Cheese Coney",
        explanation: "Doesn't matter if you're a fan of Skyline or Gold Star, everyone loves cheese coneys. Let's send each other coneys for good work and help!",
        image: "mascots/coney.webp"
      },
    contenderB:
      {
        winner: true,
        mascot: "A Round On The House",
        explanation: "Cheers to you! Nothing says \"thank you\" like having someone else pay for your beverage of choice.",
        image: "mascots/beers.webp"
      },
  },
];

export default r3Matchups;