import React, {useState, useEffect} from "react"

import Mascot from "./mascot"

const Matchup = ({matchup, side, votes, final = false}) => {

  const [hovered, setHovered] = useState(false)
  const [winnerA, setWinnerA] = useState( matchup.contenderA.winner )
  const [winnerB, setWinnerB] = useState( matchup.contenderB.winner )

  useEffect( () => {

    if (typeof votes !== "undefined") {
      setWinnerA(votes.votesA > votes.votesB)
      setWinnerB(votes.votesB > votes.votesA)
    }
    return
  }, [votes])


  return (
    <ul
      className={"matchup " + (side === "left" ? "side-left" : "side-right")}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >

      { final ? <li className="team final-text">Final Matchup</li> : null }

      <li className={"team team-top" + (hovered ? " hovered" : "")}>
        <span className={"mascot-name " + (winnerB ? "loser" : (winnerA ? "winner" : "" ))}>
          {matchup.contenderA.mascot}
        </span>
      </li>
      <li className={"team team-bottom" + (hovered ? " hovered" : "")}>
        <span className={"mascot-name " + (winnerA ? "loser" : (winnerB ? "winner" : "" ))}>
          {matchup.contenderB.mascot}
        </span>
      </li>

      {hovered ?
        <div className="hover-details">
          <div className="top-bar"><strong>Matchup</strong></div>
          <div className="hover-details-inner">
            <div className={"contender contender-a " + (winnerA ? "winner" : (winnerB ? "loser" : ""))}>
              <Mascot className="mini-image" imagePath={matchup.contenderA.image}
                   alt={"Image of " + matchup.contenderA.mascot}
              />
              <div>{matchup.contenderA.mascot}</div>
            </div>
            <div className="vs">VS.</div>
            <div className={"contender contender-b " + (winnerB ? "winner" : (winnerA ? "loser" : "") )}>
              <Mascot className="mini-image" imagePath={matchup.contenderB.image}
                   alt={"Image of " + matchup.contenderB.mascot} />
              <div>{matchup.contenderB.mascot}</div>
            </div>
          </div>
        </div>
        :
        null
      }
    </ul>
  )
}

export default Matchup