import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Logo from "./logo"


const Header = ({ title }) => (
  <header className="header-hero">

    <Link to="/">
      <Logo maxWidth="300px" maxHeight="170px" />
    </Link>
    <div className="spacer"></div>
    <div>
      <h1>{title}</h1>
      <Link className="back" to="/">&larr; Back to Voting</Link>
    </div>
  </header>
);

Header.defaultProps = {
  title: "2060 Mascot Madness",
};

Header.propTypes = {
  title: PropTypes.string,
};



export default Header;
