const r4Matchups = [
  {
    contenderA:
      {
        mascot: "Digi \"The Dinosaur\"",
        explanation: "Digi -short for digital.\n" +
          "\n" +
          "Dinosaurs made a mark in history and our company aims to make their mark in history as the best digital agency in the nation! Dinosaurs were a force to be reckon with!",
        image: "mascots/party-dinosaur.gif"
      },
    contenderB:
      {
        mascot: "Mario Coin",
        explanation: "There's an indefinite number of them to give out and if you get 100 you get a free life!\n" +
          "\n" +
          "Also, Mario embodies the essence of persistence, running through castle after castle, never giving up on finding the princess.",
        image: "mascots/coin.gif"
      },
  },
  {
    contenderA:
      {
        mascot: "Digi",
        explanation: "First off I envision graphics making this look A LOT better haha but.... my vision is a little 2060 mascot played off of our logo, called Digi. He likes to bring praise & cheer when a team member has done well. Digi says: GO TEAM!",
        image: "mascots/Digi.webp"
      },
    contenderB:
      {
        mascot: "A Round On The House",
        explanation: "Cheers to you! Nothing says \"thank you\" like having someone else pay for your beverage of choice.",
        image: "mascots/beers.webp"
      },
  },
];

export default r4Matchups;